import _ from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'

import { img_embed } from '../utils/core'
import Colors from '../utils/colors'
import IconButton from './icon_button'
import Logger from '../utils/logger'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '100%',
    width: 350,
    margin: '1em',
  },
  avatar: {
    height: 'initial',
  },
  media: {
    height: 100,
    paddingTop: '56.25%', // 16:9
  },
  extraInformation: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 10,
  },
  chips: {
    display: 'flex',
    marginLeft: 5,
    marginBottom: 5,
  },
  chip: {
    alignSelf: 'center',
    marginRight: 5,
  },
  expandButton: {
    flex: 1,
    flexFlow: 'row-reverse',
    padding: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export default function CardWithHeader({
  title,
  subtitle,
  images,
  link,
  description,
  bullets,
  chips,
  event,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)

    const event = expanded ? 'collapse' : 'expand'
    Logger.log(event)
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          images.avatar ? (
            <Avatar
              aria-label="logo"
              src={img_embed(images.avatar)}
              variant="rounded"
              className={classes.avatar}
            />
          ) : (
            <Avatar
              aria-label="logo"
              style={{ backgroundColor: Colors.get('random') }}
              variant="rounded"
            >
              {title[0]}
            </Avatar>
          )
        }
        action={
          link ? (
            <IconButton
              src="launch"
              tooltip="Check it out!"
              onClick={() => Logger.log(`launch_${event ?? ''}_${title}`, link)}
            />
          ) : null
        }
        title={title}
        subheader={subtitle}
      />
      {images.img ? (
        <CardMedia
          className={classes.media}
          image={img_embed(images.img)}
          title={title}
          onClick={() => {
            if (link) Logger.log(`launch_${event ?? ''}_${title}`, link)
          }}
          style={{ cursor: link ? 'pointer' : 'inherit' }}
        />
      ) : null}
      {description ? (
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      ) : null}
      {_.some([chips, bullets], 'length') ? (
        <div className={classes.extraInformation}>
          {_.size(chips) > 0 ? (
            <div className={classes.chips}>
              {_.map(chips, (chip, idx) => (
                <Chip
                  size="small"
                  label={chip.label}
                  key={idx}
                  className={classes.chip}
                  style={{ backgroundColor: Colors.get(...chip.color) }}
                />
              ))}
            </div>
          ) : null}
          {_.size(bullets) > 0 ? (
            <CardActions disableSpacing className={classes.expandButton}>
              <Button onClick={handleExpandClick} color="primary">
                {expanded ? 'Show Less' : 'Learn More'}
              </Button>
            </CardActions>
          ) : null}
        </div>
      ) : null}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {_.map(bullets, (text, idx) => (
            <Typography paragraph key={idx}>
              {text}
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  )
}
